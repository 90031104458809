<template>
  <section
    :style="{
      'padding-right': createCommand || $slots.extraButtons ? '15px' : 0
    }"
  >
    <div class="row">
      <form role="form" @submit.prevent>
        <div class="col-md-4" v-if="$slots.statistics">
          <slot name="statistics"></slot>
          <div class="hidden-md hidden-lg" style="margin-bottom: 10px"></div>
        </div>
        <div
          class="search-column"
          :class="[
            createCommand ? 'col-xs-9 col-sm-10 ' : 'col-xs-12',
            $slots.statistics && createCommand
              ? 'col-md-6'
              : !$slots.statistics
              ? 'col-md-10'
              : 'col-md-8'
          ]"
        >
          <div
            class="form-group"
            :class="{
              'form-group-search': createCommand || $slots.extraButtons
            }"
          >
            <div class="input-group">
              <input
                type="text"
                v-model="query"
                class="form-control search-input"
                v-bind:placeholder="$t('placeholders.search')"
                ref="query"
              />
              <div
                class="input-group-addon btn"
                v-on:click.prevent.stop="onResetButtonClick"
              >
                <i v-if="query" class="fa fa-close reset-icon"></i>
                <i v-else class="fa fa-search"></i>
              </div>
            </div>
          </div>
          <slot name="customFilters"></slot>
        </div>
        <template v-if="createCommand">
          <div
            class="form-group col-xs-3 col-sm-2 col-md-2 text-right"
            v-if="
              createCommand.commands &&
              createCommand.commands.length &&
              isCommandEnabled(createCommand)
            "
          >
            <div class="row">
              <div class="col-xs-12">
                <div class="btn-group form-group btn-new-item">
                  <button
                    type="button"
                    class="btn btn-primary"
                    data-testid="create-new"
                    v-bind:disabled="!isCommandEnabled(createCommand)"
                    v-on:click.prevent.stop="
                      onCreateButtonClick(createCommand.name)
                    "
                  >
                    <i class="fa fa-plus"></i>
                    <span class="hidden-xs">
                      {{ $tc(createCommand.title) }}
                    </span>
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span class="caret"></span>
                    <span class="sr-only">Toggle Dropdown</span>
                  </button>
                  <ul class="dropdown-menu">
                    <li
                      v-for="(item, ix) in createCommand.commands"
                      v-bind:key="ix"
                    >
                      <a
                        href="#"
                        v-on:click.prevent.stop="onCreateButtonClick(item.name)"
                      >
                        {{ $tc(item.title) }}
                      </a>
                    </li>
                  </ul>
                  <template v-if="$slots.extraButtons">
                    <slot name="extraButtons"></slot>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="form-group col-xs-3 col-sm-2 col-md-2">
            <div class="btn-group form-group btn-new-item pull-right">
              <button
                class="btn"
                :class="
                  isCommandEnabled(createCommand)
                    ? 'btn-primary'
                    : 'btn-default'
                "
                data-testid="create-new"
                v-bind:disabled="!isCommandEnabled(createCommand)"
                v-on:click.prevent.stop="
                  onCreateButtonClick(createCommand.name)
                "
              >
                <i class="fa fa-plus"></i>
                <span class="hidden-xs">
                  {{ $tc(createCommand.title) }}
                </span>
              </button>
              <template v-if="$slots.extraButtons">
                <slot name="extraButtons"></slot>
              </template>
            </div>
          </div>
        </template>
      </form>
    </div>
  </section>
</template>

<script>
export default {
  name: "SearchableTableQueryInput",
  data() {
    return {
      iQuery: ""
    };
  },
  props: {
    createCommand: {
      type: Object,
      required: false,
      default: () => null
    },
    value: {
      type: String,
      required: false,
      default: () => ""
    }
  },
  computed: {
    query: {
      set(value) {
        if (this.iQuery != value) {
          this.iQuery = value;
          this.$emit("input", this.iQuery);
        }
      },
      get() {
        return this.iQuery;
      }
    }
  },
  watch: {
    value: {
      handler(n, o) {
        if (n == o) return;
        this.query = n;
      },
      immediate: true
    }
  },
  methods: {
    isCommandEnabled(command) {
      if ("enabled" in command) {
        if (typeof command.enabled == "function") {
          return command.enabled();
        }
        return command.enabled;
      }
      return true;
    },
    resetQuery() {
      let self = this;
      this.query = "";
      this.$nextTick(function () {
        self.$refs.query.focus();
      });
    },
    onResetButtonClick() {
      this.resetQuery();
    },
    onCreateButtonClick(name) {
      this.$emit("create", name);
    }
  }
};
</script>

<style scoped>
section {
  max-width: 100%;
}

.btn-create {
  font-size: 9pt;
}

.form-group {
  margin-bottom: 0;
}

.form-group-search {
  margin-right: 15px;
  margin-left: -15px;
  padding-left: 15px;
  padding-right: 15px;
}

.input-group .form-control {
  z-index: 0 !important;
}

.dropdown-menu {
  right: 0;
  left: auto;
}

.btn-new-item {
  margin-left: -50px; /* x-margins + 2px x-borders */
}
.btn-new-item > .dropdown-toggle:not(:last-child) {
  padding-left: 6px;
  border-radius: 0;
}

.btn-group > .btn {
  min-width: 31px;
}

.reset-icon {
  text-shadow: 1px 1px 3px #dcdcdc;
  font-size: 120%;
  color: #c9423f;
}

.skin-dark .reset-icon {
  text-shadow: inherit;
  color: var(--skin-dark-light);
}
</style>
